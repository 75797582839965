import React, { useState, useEffect } from 'react'
import { Container, Grid } from '@material-ui/core'
import './_residence_amboise_actions.scss'
import axios from 'axios'

export default function ResidenceAmboiseActions(props) {
  const {update,projectId,categoryId,sendServiceId,token,projectServiceId,setProjectServiceId,serviceStatus,setServiceStatus,setResponsibleId ,setFileNumber , sendData}=props
  const [service, setService] = useState([])
  const [categoryServiceId, setCategoryServiceId] = useState(0)

  useEffect(() => {
    getAllService(categoryId)
  }, [update])

  useEffect(() => {}, [update, categoryServiceId,projectServiceId,projectId,serviceStatus])

useEffect(()=>{
  service.some((item) => {
    if (item.id == sendData.serviceId){
      setCategoryServiceId(item.serviceCategoryId)
      sendServiceId(item.id , item.servicesMetaTitle)
      setProjectServiceId(item.dbId!==undefined?item.dbId:0)
      setServiceStatus(item.status)
      setFileNumber(item.fileNumber)
      setResponsibleId(item.adminId)
    }
  })
},[service])


  const getAllService = (categoryId) => {
    let List = []
    axios
      .post(
        `https://backend.staging.bip.flykube.agifly.cloud/serviceCategory/get/projectService/` +
          projectId,
        {},
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        let data = response.data
        if (data.length > 0) {
          
          for (let i = 0; i < data.length; i++) {
            let temp = {
              id:data[i].id,
              adminId:data[i].assigned_admin_id,
              serviceId: data[i].serviceId,
              serviceCategoryId: data[i].service.serviceCategoryId,
              title: data[i].service.title,
              status:data[i].service_status_id,
              fileNumber:data[i].fileNumber,
              servicesMetaTitle: data[i].servicesMeta == null ? null:data[i].servicesMeta.title,
              isPresent: false,
              isSelected:false,
            }
            List.push(temp)
          }
          getAllSelectService(List,categoryId)
          setService(response.data)
        }
      })
      .catch((error) => {})
  }

  const getAllSelectService = (List,categoryId) => {
    let temp = []
    
    axios
      .post(
        `https://backend.staging.bip.flykube.agifly.cloud/serviceCategory/get/categoryService/${categoryId}`,
        {},
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        let data = response.data.data
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            for (let l = 0; l < data[i].services.length; l++) {
              let tempRow=data[i].services[l]
              if(tempRow.id==sendData.serviceId){
                tempRow['isSelected']=true
              }
              else{
                tempRow['isSelected']=false
              }
              tempRow['isPresent']=false
              temp.push(tempRow)
            }
          }
          for (let j = 0; j < List.length; j++) {
            for (let k = 0; k < temp.length; k++) {
              if (List[j].serviceId === temp[k].id) {
                temp[k].isPresent=true
                temp[k]['dbId']=List[j].id
                temp[k]['servicesMetaTitle']=List[j].servicesMetaTitle
                temp[k]['status']=List[j].status
                temp[k]['adminId']=List[j].adminId
                temp[k]['fileNumber']=List[j].fileNumber
              }
            }
          }
        }
        let hasServices = List.some((item) => {
          if (item.isPresent) return true
        })
        if (!hasServices) {
          setCategoryServiceId(0)
          sendServiceId(0)
        }
        setService(temp)
      })
      .catch((error) => {
        setService(temp)
      })
  }

  const handleServiceClick = (item) => {
    if (item.isPresent) {
      service.forEach((x) => (x.isSelected = false))
      item.isSelected = true
      setCategoryServiceId(item.serviceCategoryId)
      sendServiceId(item.id , item.servicesMetaTitle)
      setProjectServiceId(item.dbId!==undefined?item.dbId:0)
      setServiceStatus(item.status)
      setFileNumber(item.fileNumber)
      setResponsibleId(item.adminId)
    } else {
      setCategoryServiceId(0)
      sendServiceId(0)
    }
  }

  return (
    <Container maxWidth = 'lg'>
    <Grid
      container
      item
      sm={12}
      md={12}
      alignItems="flex-start"
      justify = 'center '
    >
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={6}
        justify = 'center'
       >
        {service.slice(0, service.length / 2).map((item) => {
          return (
            <button
              onClick={() => {item.isPresent&&
                handleServiceClick(item)
              }}
              type="button"
              className={
                item.isPresent
                  ? item.isSelected
                    ? 'selected-button-style margin-top-15 vietnam-semi-bold font-size-14 font-color-black'
                    : 'enable-button-style margin-top-15 vietnam-semi-bold font-size-14'
                  : 'disable-button-style margin-top-15 vietnam-semi-bold font-size-14'
              }
            >
              <p>{item.title}</p>
            </button>
          )
        })}
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={6}
      >
        {service.slice(service.length / 2, service.length).map((item) => {
          return (
            <button
              type="button"
              onClick={() => {item.isPresent&&
                handleServiceClick(item)
              }}
              className={
                item.isPresent
                  ? item.isSelected
                    ? 'selected-button-style margin-top-15 vietnam-semi-bold font-size-14'
                    : 'enable-button-style margin-top-15 vietnam-semi-bold font-size-14'
                  : 'disable-button-style margin-top-15 vietnam-semi-bold font-size-14'
              }
            >
              <p>{item.title}</p>
            </button>
          )
        })}
      </Grid>
    </Grid>
    </Container>
  )
}

ResidenceAmboiseActions.defaulProps={
  update:false,
  projectId:0,
  categoryId:0,
  sendServiceId:0,
  token:'',
  projectServiceId:0,
  setProjectServiceId:0,
  serviceStatus:0,
  setServiceStatus:0,
  setResponsibleId:[]
}
