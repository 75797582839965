import axios from "axios";

export function isLocalDataValid() {
    const token = localStorage.getItem("authToken");
    const userDetails = localStorage.getItem("loggedInUserDetails");
    return token !== null && userDetails !== null;
}

export function isTokenValid(history) {
    const token = localStorage.getItem('authToken');
    if(token) {
        axios.post("https://backend.staging.bip.flykube.agifly.cloud/user/latestData", {}, {
            headers: {
                authorization: `bearer ${token}`
            }
        }).then(() => {

        }).catch(() => {
            localStorage.clear()
            history.push('/')
        })
    }
    else {
        localStorage.clear()
        history.push('/')
    }
}